require('bc-image-loader');

const $ = require('jquery');
global.jQuery = $;

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();

require('../../../../shared/lib/handlebarsHelpersTime')();
require('../../common/js/sorting');
const Cookies = require('js-cookie');
const utils = require('../../common/js/utils');

class BcChronicle {
  constructor () {
    this.cookieSearchValue = null;
    this.isNavMenuOpen = false;
  }

  init () {
    this.initLeftContainer();
    this.initCookies();
    this.initInteractions();
    this.initSharePopups();
    this.truncateHero();
  }

  //This function is needed to fix an issue with IE where the video details would get misaligned
  initLeftContainer () {
    $('.left-container').each(function () {
      if ($(this).children().length === 0) {
        $(this).css('display', 'none');
      }
    });
  }

  initCookies () {
    this.cookieSearchValue = Cookies.get('BCCHRONICLE_SQUERY');

    // if we've searched previously, this cookie will be set however
    // we will delete it if we've reached a non-search related page
    if (window.site.activePage !== 'search' && window.site.activePage !== 'detail') {
      this.cookieSearchValue = null;
      Cookies.remove('BCCHRONICLE_SQUERY');
      return;
    }

    // if we don't have a cookie beyond this point,
    if (!this.cookieSearchValue) {
      return;
    }

    // signal the "back to search results" appearance
    $('body').addClass('from-search');
    $('#chron-search-return__link').attr('href', this.createSearchUrl(this.cookieSearchValue));
  }

  initInteractions () {
    $('.chron-search__input').on('keyup', e => {
      if (e.keyCode === 13) {// enter key
        if (e.originalEvent.isComposing) { // don't execute search if keyboard is composing input
          return;
        }
        this.submitSearchWithQuery($(e.currentTarget).val());
      }
    });

    $('.chron-search__icon').on('click', e => {
      e.preventDefault();
      this.submitSearchFromInput($(e.currentTarget).data('from-input'));
    });

    $('.chron-nav__hamburger').on('click', e => {
      e.preventDefault();
      this.setNavMenuOpen(true);
    });

    $('.chron-nav__menu-close').on('click', e => {
      e.preventDefault();
      this.setNavMenuOpen(false);
    });
  }

  initSharePopups () {
    $(document).on('click', '.video-share__icon', function () {
      const $popup = $(this).closest('.video-share');
      $popup.toggleClass('open');
    });
  }

  setNavMenuOpen (isOpen) {
    this.isNavMenuOpen = isOpen;

    const $targets = $('body, .chron-nav-menu');
    if (this.isNavMenuOpen) {
      $targets.addClass('nav-menu-open');
    } else {
      $targets.removeClass('nav-menu-open');
    }
  }

  submitSearchFromInput (inputId) {
    this.submitSearchWithQuery($('#' + inputId).val());
  }

  submitSearchWithQuery (query) {
    if (!query) {
      return;
    }

    // update a cookie so that we can show a "back to search results"
    // as a result of processing in "initCookies"
    Cookies.set('BCCHRONICLE_SQUERY', query);

    // goto search
    document.location.href = this.createSearchUrl(query);
  }

  createSearchUrl (query) {
    return utils.urlWithDevice(window.baseUrl + '/search?q=' + encodeURIComponent(query));
  }

  truncateHero () {
    const $description = $('.video-meta-right__desc');
    const $truncate = $('.video-meta-right__desc-inner');
    if ($truncate.length < 1) {
      return;
    }

    // reset first
    const truncateOriginalText = $truncate.data('original-text');
    const truncateOriginalTokens = truncateOriginalText.split(' ');
    $truncate.html(truncateOriginalText);

    // truncate
    let wasTruncated = false;
    while (truncateOriginalTokens.length > 1 && $truncate.height() > $description.height()) {
      truncateOriginalTokens.pop();
      $truncate.html(truncateOriginalTokens.join(' '));
      wasTruncated = true;
    }

    // if truncated, add ellipsis
    if (wasTruncated) {
      if (truncateOriginalTokens.length > 0) {
        truncateOriginalTokens.pop();
      }

      truncateOriginalTokens.push('...');
      $truncate.html(truncateOriginalTokens.join(' '));
    }
  }
}

const templates = {
  TEMPLATE_VIDEO_ITEM: window.bcGallery.getTemplatePath('/sites/the_chronicle/partials/video-item-no-partial.hbs'),
  TEMPLATE_SHARE_ELEMENT: window.bcGallery.getTemplatePath('/sites/the_chronicle/partials/share-element.hbs'),
  TEMPLATE_SEQ_LABEL: window.bcGallery.getTemplatePath('/sites/the_chronicle/partials/sequence-label.hbs'),
  TEMPLATE_CTA: window.bcGallery.getTemplatePath('/components/cta.hbs'),
  TEMPLATE_DOWNLOAD_LINK: window.bcGallery.getTemplatePath('/sites/the_chronicle/partials/video-download-link.hbs'),
};

class BcChronicleLazyLoad {
  constructor () {

    this.thresholdPercentage = 0.9;// % from page bottom to start loading

    this.isLoading = false;
    this.isLoadingDone = false;

    this.loadingPage = 1;// start at page #1 since the first page is always already on the page

    this.ctaApiData = null;// will be loaded before enabling scroll behavior

    this.templateCache = {};
  }

  loadTemplate (templateUrl, callback) {
    if (this.templateCache[templateUrl]) {
      return callback(this.templateCache[templateUrl]);
    }

    $.ajax({
      url: templateUrl,
      success: contents => {
        this.templateCache[templateUrl] = window.bcGallery.Handlebars.compile(contents);
        callback(this.templateCache[templateUrl]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  init () {
    // used by sequence label partial in ajax context
    if (window.site && window.bcGallery.template.chronicle.siteDynamicCustom) {
      window.site.dynamicCustom = window.bcGallery.template.chronicle.siteDynamicCustom;
    }

    if (!$('#bc-chronicle-ll-container').data('required')) {
      // if all videos already loaded, no need for lazy load
      this.endLoading();
    } else {
      // otherwise, might be starting at a page other than the first
      this.loadingPage = parseInt($('#bc-chronicle-ll-container').data('next-page'), 10);
    }

    // load CTA data prior to starting lazy load
    // this includes the CTA API data and the cta component template
    const self = this;
    this.loadTemplate(templates.TEMPLATE_CTA, () => {
      $.ajax({
        url: window.baseUrl + '/api/ctas/' + window.site.activePage + '/ignoreMobileTemplate',
        success: data => {
          if (data && data.result) {
            self.ctaApiData = data.result;
          } else {
            self.ctaApiData = { };
          }

          $(window).scroll(() => {
            this.onScroll();
          });
        },
        complete: function (xhr) {
          if (xhr.status === 401) {
            window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
          }
        },
      });
    });
    this.loadTemplate(templates.TEMPLATE_DOWNLOAD_LINK, () => {
      this.loadVideoDownloads();
    });
  }

  onScroll () {
    const windowHeight = $(window).height();
    const windowScrollTop = $(window).scrollTop();
    const documentHeight = $(document).outerHeight();
    const threshold = (documentHeight - windowHeight) * this.thresholdPercentage;

    if (windowScrollTop >= threshold) {
      this.loadNextPage();
    }
  }

  loadNextPage () {
    if (this.isLoading || this.isLoadingDone) {
      return;
    }

    this.isLoading = true;

    this.loadTemplate(templates.TEMPLATE_VIDEO_ITEM, () => {
      this.loadTemplate(templates.TEMPLATE_SHARE_ELEMENT, () => {
        this.loadTemplate(templates.TEMPLATE_SEQ_LABEL, () => {
          let loadUrl = null;
          switch (window.site.activePage) {
            case 'detail':// same as index
            case 'category':// same as index
            case 'index':
              loadUrl = window.baseUrl + '/api/videos/' + window.category.slug + '?page=' + this.loadingPage;
              break;

            case 'search':
              loadUrl = window.baseUrl + '/api/videos?q=' + window.query.q + '&page=' + this.loadingPage;
              break;
          }

          if (loadUrl) {
            $.ajax(loadUrl, {
              dataType: 'json',
              success: data => {
                this.loadNextPageDidSuceed(data);
              },
              complete: function (xhr) {
                if (xhr.status === 401) {
                  window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
                }
              },
            });
          }
        });
      });
    });
  }

  loadNextPageDidSuceed (data) {
    const self = this;
    const $container = $('#bc-chronicle-ll-container');
    const videos = data.result.items;
    const videosStartIndex = data.result.start - 1;

    // page helps autoplay next and is required when ajax'ing
    const query = window.query;
    query.page = this.loadingPage;

    const templateData = {
      video: null,// populated in following loop during iteration
      category: window.category,
      translations: window.translations,
      imageTranscoder: window.BCLS.imageTranscoder,
      site: window.site,
      baseUrl: window.baseUrl,
      urlOptions: window.bcGallery.template.chronicle.urlOptions,
      ctas: this.ctaApiData,
      query: query,
      locale: window.locale,
    };

    for (let i = 0; i < videos.length; i++) {
      templateData.video = videos[i];

      const $item = $(this.templateCache[templates.TEMPLATE_VIDEO_ITEM](templateData));
      $item.find('.sequence-label-partial').html(this.templateCache[templates.TEMPLATE_SEQ_LABEL](templateData));
      $item.find('.share-element-partial').html(this.templateCache[templates.TEMPLATE_SHARE_ELEMENT](templateData));
      $item.appendTo($container);

      // if a CTA is available, inject it here
      // increase detail page index by 1 since the .hbs is
      // not skipping the first video like home / category
      let ctaIdIndex = (i + videosStartIndex);
      if (window.site.activePage === 'detail') {
        ctaIdIndex++;
      }

      let ctaId = 'video-list-cta_' + ctaIdIndex;
      if (window.site.isMobile) {
        ctaId = 'video-list-cta-mobile_' + ctaIdIndex;
      }

      if (this.ctaApiData[ctaId]) {
        const ctaData = {
          ctas: self.ctaApiData,
          id: ctaId,
          ignoreCustomDimensions: true,
        };

        // CTA component is pre-loaded in init function so we can do this synchronously
        // to make sure the injection happens at the correct time and in the correct location
        let ctaHtml = '<div class="container-fluid video-item as-cta">';
        ctaHtml += this.templateCache[templates.TEMPLATE_CTA](ctaData);
        ctaHtml += '</div>';

        $container.append(ctaHtml);
      }
    }

    this.isLoading = false;
    window.bcGallery.imageLoader.loadImages();
    this.loadVideoDownloads();

    if (data.result.nextPage) {
      this.loadingPage = parseInt(data.result.nextPage, 10);
    } else {
      this.endLoading();
    }
  }

  loadVideoDownloads () {
    $('.video-share:not([data-download-loaded="true"])').each((index, element) => {
      const $el = $(element);
      window.bcGallery.videoDownloads.getSources($el.data('video-id'), sources => {
        $el.attr('data-download-loaded', 'true');
        if (!sources.default) {
          return;
        }
        const downloadLinkHtml = this.templateCache[templates.TEMPLATE_DOWNLOAD_LINK]({
          sources: sources,
          translations: window.translations,
        });

        $el.find('ul').append(downloadLinkHtml);
        $el.removeClass('video-share-hidden');
      });
    });
  }

  endLoading () {
    this.isLoadingDone = true;
    $('#bc-chronicle-ll-spinner').remove();
  }
}

const bcChronicle = new BcChronicle();
const bcChronicleLazyLoad = new BcChronicleLazyLoad();

$(document).ready(function () {
  bcChronicle.init();
  bcChronicleLazyLoad.init();
  window.bcGallery.imageLoader.ignoreHeight = true;
  window.bcGallery.imageLoader.loadImages();
});

$(window).resize(function () {
  bcChronicle.truncateHero();
});

window.onload = function () {
  bcChronicle.truncateHero();
};
